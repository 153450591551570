// Vendors
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  addToast,
  Button,
  TextAreaInput,
  Icon,
  TextInput,
  Alert,
  Modal,
} from "@octano/global-ui";

// Hooks
import { useModal } from "hooks";

// Styles
import "assets/scss/_modal_custom_size.scss";

// API
import dayjs from "dayjs";
import { LeaveApprovalRequest } from "../types";

export interface LeaveApprovalFormProps {
  recordToEdit: LeaveApprovalRequest;
  onToggle: () => void;
  onRefresh: () => void;
  handleLeaveRequest?: (
    leaveApprovalId: string,
    payload: { approved: boolean; comment?: string }
  ) => Promise<any>;
}

export const LeaveApprovalForm: React.FC<LeaveApprovalFormProps> = ({
  recordToEdit,
  onToggle,
  onRefresh,
  handleLeaveRequest,
}) => {
  const { modalClose } = useModal();

  // Form
  const { control, handleSubmit } = useForm<{
    comment: string;
    response: string;
  }>({
    defaultValues: {
      comment: "",
      response: recordToEdit.response || "",
    },
  });

  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [surrogateApproved, setSurrogateApproved] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const readOnly = !handleLeaveRequest;

  // Request
  const handleSubmitRequest = (approved: boolean) => {
    return handleSubmit(async (data) => {
      if (!handleLeaveRequest) {
        return;
      }

      setIsLoading(true);

      // Surrogate
      if (recordToEdit.isSurrogate && !confirm) {
        setConfirm(true);
        setSurrogateApproved(approved);
        setShowConfirmationModal(true);
        setIsLoading(false);
        return;
      }

      // API
      try {
        const response = await handleLeaveRequest(
          recordToEdit.leaveApprovalId,
          {
            approved: approved ?? false,
            comment: data.comment,
          }
        );

        if (!response.error) {
          if (response.data.p_det_error) {
            setErrorMessage(response.data.p_det_error);
            setIsLoading(false);
            setShowConfirmationModal(false);
            return;
          }
          if (response.data.approved) {
            addToast({
              text: "Solicitud aprobada con éxito",
              autoClose: 5000,
              icon: "success",
              color: "success",
            });
          } else {
            addToast({
              text: "Solicitud rechazada con éxito",
              autoClose: 5000,
              icon: "success",
              color: "success",
            });
          }
        } else {
          addToast({
            text: "Error al responderla solicitud",
            autoClose: 5000,
            icon: "error",
            color: "danger",
          });
        }
        modalClose();
      } catch (error) {
        addToast({
          text: "Error al responder la solicitud",
          autoClose: 5000,
          icon: "error",
          color: "danger",
        });
      }
      setIsLoading(false);
      onRefresh();
      onToggle();
      return;
    });
  };

  return (
    <div>
      <h4 className="py-2 fs-22 text-dark text-center">
        {recordToEdit.doneBy ? "Detalle de solicitud" : "¿Aprobar Solicitud?"}
      </h4>
      <div className="modal-subtitle py-2">
        <Icon name="information" color="info" />
        <span className="pl-2">
          <b>Solicitud realizada por: </b>
          {recordToEdit.requestBy
            .split(" ")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ")}
        </span>
      </div>
      {readOnly && (
        <div className="modal-subtitle py-2">
          <Icon name="information" color="info" />
          <span className="pl-2">
            <b>Solicitada a: </b>
            {recordToEdit.requestTo
              .split(" ")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
          </span>
        </div>
      )}
      <div>
        <form onSubmit={(e) => e.preventDefault()} className="container">
          {recordToEdit.isSurrogate && (
            <div className="row">
              <div className="col pt-3">
                <Alert
                  text="Estás viendo esta solicitud como Subrogante de Jefatura."
                  color="info"
                  icon="information"
                  size="sm"
                />
              </div>
            </div>
          )}
          <div className="row px-3 pt-3 pb-2">
            <div className="col p-0 ">
              <TextInput
                name="requestType"
                label="TIPO DE SOLICITUD"
                value={recordToEdit.requestType}
                rules={{ required: true }}
                disabled={true}
              />
            </div>
          </div>
          <div className="row px-3">
            <table className="g-table table table-borderless">
              <thead
                style={{
                  backgroundColor: "#4a90d0",
                  color: "white",
                }}
              >
                <tr>
                  {recordToEdit.requestType === "Días administrativos" && (
                    <th>
                      <div className="mx-auto fw-400 pl-4">Tipo de permiso</div>
                    </th>
                  )}
                  {!recordToEdit.isFullDay && (
                    <th>
                      <div className="mx-auto fw-400 pl-4">Horario</div>
                    </th>
                  )}
                  <th>
                    <div className="text-center mx-auto fw-400">Fechas</div>
                  </th>
                  <th>
                    <div className="text-center mx-auto fw-400">Cantidad</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {recordToEdit.requestType === "Días administrativos" && (
                    <td>
                      <div className="mx-auto fw-400 pl-4">
                        {recordToEdit.isFullDay
                          ? "Días completos"
                          : "Medio día"}
                      </div>
                    </td>
                  )}
                  {!recordToEdit.isFullDay && (
                    <td>
                      <div className="mx-auto fw-400 pl-4">
                        {recordToEdit.isMorning ? "Mañana" : "Tarde"}
                      </div>
                    </td>
                  )}
                  <td>
                    <div className="text-center mx-auto fw-400">
                      {dayjs(recordToEdit.startDate).format("DD/MM/YYYY")}
                      {recordToEdit.endDate &&
                        ` - ${dayjs(recordToEdit.endDate).format(
                          "DD/MM/YYYY"
                        )}`}
                    </div>
                  </td>
                  <td>
                    <div className="text-center mx-auto fw-400">
                      {recordToEdit.isFullDay
                        ? recordToEdit.requestedDays
                        : 0.5}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col pb-5">
              <TextAreaInput
                label="Respuesta"
                name="response"
                value={recordToEdit.response}
                disabled={readOnly || !!recordToEdit.doneBy}
                {...(!recordToEdit.doneBy && { control })}
              />
            </div>
          </div>
          {errorMessage && (
            <div className="row">
              <div className="col pb-3">
                <Alert
                  text={errorMessage}
                  color="danger"
                  icon="error"
                  size="sm"
                />
              </div>
            </div>
          )}
        </form>
      </div>
      <div className="col modal-action">
        {!recordToEdit.doneBy && !readOnly ? (
          <>
            <Button
              text="Cancelar"
              outlined
              size="lg"
              fullwidth
              className="modal-action-btn"
              onClick={() => onToggle()}
            />
            <Button
              text="Rechazar"
              color="danger"
              size="lg"
              fullwidth
              className="modal-action-btn"
              onClick={handleSubmitRequest(false)}
              loading={isLoading}
            />
            <Button
              text="Aprobar"
              size="lg"
              fullwidth
              onClick={handleSubmitRequest(true)}
              className="modal-action-btn"
              loading={isLoading}
            />
          </>
        ) : (
          <Button
            text="Volver"
            size="lg"
            fullwidth
            className="modal-action-btn"
            onClick={() => onToggle()}
          />
        )}
      </div>
      <Modal
        isOpen={showConfirmationModal}
        toggle={() => {
          setShowConfirmationModal(!showConfirmationModal);
        }}
        size="md"
        unmountOnClose
      >
        <div>
          <div className="py-4 fs-22 text-dark text-center">
            Estás
            {surrogateApproved ? " aprobando " : " rechazando "}
            como <b>subrogante</b> esta solicitud. ¿Estás seguro de hacerlo?
          </div>
          <div className="col modal-action">
            <Button
              text="Cancelar"
              outlined
              size="lg"
              fullwidth
              className="modal-action-btn"
              onClick={() => onToggle()}
            />
            <Button
              text={surrogateApproved ? "Aprobar" : "Rechazar"}
              color={surrogateApproved ? "primary" : "danger"}
              size="lg"
              fullwidth
              onClick={handleSubmitRequest(surrogateApproved)}
              className="modal-action-btn"
              loading={isLoading}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

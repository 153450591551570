import LeavesApprovalsTable from "components/leaveApprovals/parts/LeaveApprovalsTable";
import { CardLayout } from "components/CardLayout";
import LeaveRequestsLoader from "./Loaders/LeaveRequestsLoader";

const LeaveRequests: React.FC = () => {
  return (
    <section id="aprobaciones" className="pt-3">
      <CardLayout>
        <LeaveRequestsLoader>
          {(data) => {
            return (
              <>
                <div className="text-light fw-400 fs-18 mb-5">
                  A continuación se muestran las solicitudes de todos los
                  colaboradores.
                </div>
                <LeavesApprovalsTable
                  data={data.leaveApprovals}
                  onRefresh={data.refresh}
                />
              </>
            );
          }}
        </LeaveRequestsLoader>
      </CardLayout>
    </section>
  );
};

export default LeaveRequests;

import { SidenavCategoryType, SidenavLinkType } from "@octano/global-ui";
import { tabsPermitsVacations, tabsRemunerations } from "config/constants";
import { PermissionName } from "../types/auth";

export interface WithPermissions {
  requiredPermissions?: PermissionName[];
  allPermisionsRequired?: boolean;
}

export type LinkWithPermissions = SidenavLinkType & WithPermissions;

export type SubLinkWithPermissions = SidenavCategoryType["links"][number] &
  WithPermissions;

export type CategoryWithPermissions = Omit<SidenavCategoryType, "links"> & {
  links: SubLinkWithPermissions[];
};

export type SidenavLinksWithPermissions =
  | LinkWithPermissions
  | CategoryWithPermissions;

export const SIDENAV_LINKS: SidenavLinksWithPermissions[] = [
  {
    name: "Remuneraciones",
    icon: "pay_module",
    requiredPermissions: [PermissionName.recoverShow],
    links: [
      {
        path: tabsRemunerations[0].path,
        name: "Liquidaciones",
        requiredPermissions: [PermissionName.recoverShowLiquidationsTab],
      },
      {
        path: tabsRemunerations[1].path,
        name: "Asignaciones y Haberes",
        requiredPermissions: [PermissionName.recoverShowAssignmentAssetsTab],
      },
      {
        path: tabsRemunerations[2].path,
        name: "Descuentos",
        requiredPermissions: [PermissionName.recoverShowDiscountsTab],
      },
    ],
  },
  {
    path: "/contratos",
    name: "Contratos",
    icon: "classes_history",
    requiredPermissions: [PermissionName.contractsShow],
  },
  {
    icon: "vacation",
    name: "Permisos y vacaciones",
    path: "/permisos-y-vacaciones",
    requiredPermissions: [PermissionName.permitsVacationsShow],
  },
  {
    icon: "request",
    name: "Aprobación de Permisos",
    path: "/aprobacion-permisos",
    requiredPermissions: [PermissionName.permitsVacationsShow],
  },
  {
    icon: "request",
    name: "Solicitudes",
    path: "/solicitudes",
    requiredPermissions: [PermissionName.requestsShow],
  },
  {
    icon: "certification",
    name: "Certificados",
    path: "/certificados",
    requiredPermissions: [PermissionName.certificatesShow],
  },
  {
    icon: "user",
    name: "Datos personales",
    path: "/datos-personales",
    requiredPermissions: [PermissionName.personalDateShow],
  },
  {
    icon: "schedule",
    name: "Revisión de Solicitudes",
    path: "/solicitudes-permisos-vacaciones",
    requiredPermissions: [PermissionName.erpAdmin],
  },
];

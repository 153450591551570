// Vendors
import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";

// Assets
import logo from "assets/images/logo_campus_black.png";
import firm from "assets/images/firma.png";
import sello from "assets/images/sello.png";

//Components
import { Firm } from "components/pdf/Firm";
import { useSelector } from "react-redux";
import { RootState } from "store";

const styles = StyleSheet.create({
  foot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  firmWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "10px 0px",
  },
  firm: {
    display: "flex",
    alignItems: "center",
    marginRight: "23px",
  },

  firmDescription: {
    marginTop: 20,
    marginBottom: 40,
  },
});

export const Footer: React.FC = () => {
  const certificates = useSelector(
    (state: RootState) => state.ordsReducer.certificates
  );

  return (
    <View style={styles.foot}>
      <View>
        <View
          style={{
            marginTop: 22,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text style={{ fontSize: "16px" }}>
            {
              "Se extiende el presente certificado a petición del interesado para los fines que estime convenientes"
            }
          </Text>
        </View>
      </View>

      <Text style={styles.firmDescription}>{certificates?.p_todo || ""}</Text>

      <Text style={styles.firmDescription}>
        {certificates?.p_destino || ""}
      </Text>
    </View>
  );
};

import { ServerStatus } from "./serverStatus";

export interface User extends ServerStatus {
  email: string;
  full_name: string;
  profession: string;
  profile_image: string;
  permissions?: Permission["name"][];
}

export interface Relationship {
  rut: string;
  name: string;
  parentesco: string;
  sexo: string;
  birthday: any;
  age: string;
}

export interface Family extends ServerStatus {
  relationship: Relationship[];
}

export type Auth = {
  loaded: boolean;
  isAuthenticated: boolean;
  expiredAuthenticated: boolean;
  user: User;
  login: ServerStatus;
  recover: ServerStatus;
  activate: ServerStatus;
  family: Family;
};

export interface Permission {
  id: number;
  namePublic: string;
  name: PermissionName;
}

export enum PermissionName {
  activateShow = "activate.show",
  exampleDelete = "example.show",
  recoverShow = "recover.show",
  recoverShowLiquidationsTab = "recover.show.liquidations.tab",
  recoverShowAssignmentAssetsTab = "recover.show.assignmentAssets.tab",
  recoverShowDiscountsTab = "recover.show.discounts.tab",
  contractsShow = "contracts.show",
  contractsShowDetailesContractTab = "contracts.show.detailesContract.tab",
  contractsShowDetailesContractDependencyTab = "contracts.show.detailesContractDependency.tab",
  contractsShowDetailesContractSalaryTab = "contracts.show.detailesContractSalary.tab",
  permitsVacationsShow = "permitsVacations.show",
  permitsVacationsShowPermitsTab = "permitsVacations.show.permits.tab",
  permitsVacationsShowVacationsTab = "permitsVacations.show.vacations.tab",
  requestsShow = "requests.show",
  certificatesShow = "certificates.show",
  personalDateShow = "personalDate.show",
  erpAdmin = "erp-admin",
}

import { ReactElement, useCallback } from "react";
import {
  AuthenticationError,
  AxiosResult,
  AxiosResultDefaultError,
} from "../../../api/request2";
import { createFetchContext } from "components/context/FetchContextFactory";
import Loading from "components/Loading";
import DisplayError from "components/info/DisplayError";
import { getData } from "../api";
import {
  LeaveApprovals,
  LeaveApprovalsResponse,
} from "components/leaveApprovals/types";

interface BaseLoaderProps {
  children: (data: {
    leaveApprovals: LeaveApprovals[];
    readonly: boolean;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  LeaveApprovalsResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useLeaveApprovalsLoader() {
  return useFetch();
}

export const LeaveApprovalsLoaderConsumer = FetchConsumer;

export default function LeaveApprovalsLoader({ children }: BaseLoaderProps) {
  const request = useCallback(async (): Promise<
    AxiosResult<LeaveApprovalsResponse, AxiosResultDefaultError>
  > => {
    return getData();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, data, error, refresh }) => {
          if (loading) {
            return <Loading />;
          } else if (error) {
            return (
              <DisplayError typeError={error.code} retryAction={refresh} />
            );
          } else if (data) {
            return children({
              leaveApprovals: data.leaveApprovals,
              readonly: false,
              refresh,
            });
          } else {
            return null;
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}

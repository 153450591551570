import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { LeaveApprovals } from "../types";

export interface LeaveFiltersState {
  requestType?: string;
  approved?: boolean | null;
  createdAt?: string;
  requestBy?: string;
}

export function useLeaveFilters(data: LeaveApprovals[]) {
  const [filters, setFilters] = useState<LeaveFiltersState>({});

  const handleFilterChange = (key: keyof LeaveFiltersState, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return Object.keys(filters).every((key) => {
        const filterValue = filters[key as keyof LeaveFiltersState];

        if (key === "approved") {
          if (filterValue === undefined) return true;
          return item.approved === filterValue;
        }

        if (!filterValue) return true;

        if (key === "createdAt") {
          const itemYear = dayjs(item.createdAt).year().toString();
          return itemYear === filterValue;
        }

        if (key === "requestType") {
          if (filterValue === "Otros permisos") {
            return (
              item.requestType !== "Vacaciones" &&
              item.requestType !== "Días administrativos"
            );
          } else {
            return item.requestType
              ?.toString()
              .toLowerCase()
              .includes(filterValue.toString().toLowerCase());
          }
        }

        if (key === "requestBy") {
          return item.requestBy
            ?.toString()
            .toLowerCase()
            .includes(filterValue.toString().toLowerCase());
        }

        return item[key as keyof LeaveApprovals]
          ?.toString()
          .toLowerCase()
          .includes(filterValue.toString().toLowerCase());
      });
    });
  }, [data, filters]);

  const availableYears = useMemo(() => {
    return Array.from(
      new Set(data.map((item) => dayjs(item.createdAt).year()))
    ).map((year) => ({
      label: year.toString(),
      value: year.toString(),
    }));
  }, [data]);

  const availableRequesters = useMemo(() => {
    return Array.from(new Set(data.map((item) => item.requestBy))).map(
      (requester) => ({
        label: requester,
        value: requester,
      })
    );
  }, [data]);

  return {
    filters,
    handleFilterChange,
    filteredData,
    availableYears,
    availableRequesters,
  };
}

import request from "api/request2";
import { LeaveApprovalsResponse } from "components/leaveApprovals/types";

const LEAVE_REQUESTS_MAINTAINER = {
  DATA: "/leaves/requests",
};

export function getData() {
  return request<LeaveApprovalsResponse>(LEAVE_REQUESTS_MAINTAINER.DATA, {
    method: "GET",
  });
}

import { Modal } from "@octano/global-ui";
import { LeaveApprovalForm } from "./LeaveApprovalForm";
import { LeaveApprovalRequest } from "../types";

interface LeaveApprovalModalProps {
  show: boolean;
  recordToEdit: LeaveApprovalRequest | null;
  toggleModal: () => void;
  onRefresh: () => void;
  handleLeaveRequest?: (
    leaveApprovalId: string,
    payload: { approved: boolean; comment?: string }
  ) => Promise<any>;
}

export const LeaveApprovalModal = ({
  recordToEdit,
  show,
  toggleModal,
  onRefresh,
  handleLeaveRequest,
}: LeaveApprovalModalProps) => {
  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" unmountOnClose>
      {recordToEdit && (
        <LeaveApprovalForm
          recordToEdit={recordToEdit}
          onToggle={toggleModal}
          onRefresh={onRefresh}
          handleLeaveRequest={handleLeaveRequest}
        />
      )}
    </Modal>
  );
};
